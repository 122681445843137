import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';

export function getImages(execution_id) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/images/${execution_id}`)
            .then(response => {
                dispatch({
                    type: types.GET_IMAGES,
                    payload: response.data.images
                });
            });
    }
}

export function getImagesOfTarget(target, source) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/images/target/${target}/${source}`)
            .then(response => {
                dispatch({
                    type: types.GET_IMAGES,
                    payload: response.data.images,
                });
            })
            .catch(error => {
                dispatch({
                    type: types.GET_IMAGES,
                    payload: [],
                });
            });
    }
}

export function getShapImages(executionId) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/executions/shap/${executionId}`)
            .then(response => {
                dispatch({
                    type: types.GET_SHAP,
                    payload: response.data.images
                });
            });
    }
}

export function getCAMImages(executionId) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/executions/cam/${executionId}`)
            .then(response => {
                dispatch({
                    type: types.GET_CAM,
                    payload: response.data.images
                });
            });
    }
}

