
import { styled } from '@mui/material/styles';

export const CommonTitle = styled('div')(({ theme }) => {
    return {
        fontWeight: '500',
        fontFamily: theme.fontFamily,
        color: theme.fontColor,
        fontSize: '24px',
        // marginBottom: '10px'
    };
});

export const Error = styled('div')(({ theme, valid }) => {
    return {
        width: 'calc(100% - 25px)',
        display: valid === false ? 'block' : 'none',
        color: theme.palette.error.main,
        marginBottom: '10px',
        fontSize: '12px',
    };
});
