import * as types from '../constants/ActionTypes';

const defaultState = {
  images: [],
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_IMAGES: {
        return { ...state, images: [...action.payload] };
    }

    case types.CHANGE_SOURCE: {
      return { ...state, images: [] };
    }
    
    default:
      return { ...state }
  }
}