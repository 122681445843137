import { Component } from 'react';
import { ConfiguratedContainer } from 'components/common';
import ResNet50eMetrics from './ResNet50Metrics';
import { connect } from 'react-redux';

class ModelPerformance extends Component {
    render() {
        
        return (
            <ConfiguratedContainer className="peformances-performance-model" configuration={{
                maxWidth: '1000px',
                margin: 'auto'
            }}>
                <ResNet50eMetrics model={this.props.model}/>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        model: store.models.model
    };
}



export default connect(mapStoreToProps, { })(ModelPerformance);
