import axios from 'axios';
import * as types from '../constants/ActionTypes';

const defaultState = {
  execution: null,
  targets: []
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_EXECUTION: {
        return { ...state, execution: [...action.payload] };
    }

    case types.GET_TARGETS: {
      return { ...state, targets: [...action.payload] };
    }

    default:
      return { ...state }
  }
}
