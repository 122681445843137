import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';

export function createConcept(data) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/concepts`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

export function loadConcept(concept) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/concepts/${concept}`)
        .then(response => {
            dispatch({
                type: types.GET_CONCEPT,
                name: concept,
                images: response.data.images
            });
        })
        .catch(error => {
            dispatch({
                type: types.RESET_CHART
            });
        });
    }
}
