import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';

export function updateModel(model) {
    return function (dispatch) {
        dispatch({
            type: types.CHANGE_MODEL,
            payload: model
        });
    }
}

export function updateSource(source) {
    return function (dispatch) {
        dispatch({
            type: types.CHANGE_SOURCE,
            payload: source
        });
    }
}
