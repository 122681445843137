import { Component } from 'react';
import { connect } from 'react-redux';
import { ConfiguratedContainer, withRouter } from 'components/common';
import BarChart from 'components/common/charts/BarChart';
import { getChart } from 'actions/charts';
import { explainTCAV, loadTarget } from 'actions/executions';
import { createConcept, loadConcept } from 'actions/concepts';
import Title from 'components/common/Title';
import DropzoneComponent from './DropzoneComponent';
import { getImagesOfTarget } from 'actions/images';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Modal,
  Box,
  Typography,
} from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';


const WhiteSelect = styled(Select)`
  background-color: white;
`;

const WhiteTextField = styled(TextField)`
  background-color: white;
`;


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};

class ExplanationTCAV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      numberOfImages: 10,
      numberOfRandomFolders: 0,
      targetClass: "zebra",
      selectedConcepts: ['dotted', 'striped', 'zigzagged'],
      selectedLayers: ['inception4c', 'inception4d', 'inception4e'],
      isModalOpen: false,
      newConceptName: '',
      newConceptImages: [],
      errorMessage: '',
      conceptList: ['dotted', 'striped', 'zigzagged'],
    };
    this.onChange = this.onChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.runSimulation = this.runSimulation.bind(this);
    this.handleConceptToggle = this.handleConceptToggle.bind(this);
    this.handleLayersToggle = this.handleLayersToggle.bind(this);
    this.onSelectTarget = this.onSelectTarget.bind(this);
  }

  componentWillMount() {
    const { selectedConcepts } = this.state;

    for (const concept of selectedConcepts) {
      this.props.loadConcept(concept)
    }
    const { source } = this.props;

    this.props.loadTarget(source);
  }

  handleConceptToggle(concept) {
    const { selectedConcepts } = this.state;
    if (selectedConcepts.indexOf(concept) > -1) {
      const conceptIndex = selectedConcepts.indexOf(concept);
      selectedConcepts.splice(conceptIndex, 1);
    } else {
      selectedConcepts.push(concept);
    }
    this.setState({ selectedConcepts: selectedConcepts });
  }

  handleLayersToggle(event) {
    this.setState({ selectedLayers: event.target.value });
  }

  onValueChange(value) {
    this.setState({
      conceptInput: value.value
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSelectTarget(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    const { source } = this.props;
    this.props.getImagesOfTarget(event.target.value, source);
  }


  handleFileUpload = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => file.type.startsWith('image/'));
    if (validFiles.length !== acceptedFiles.length) {
      this.setState({
        errorMessage: 'Only image files are allowed.',
      });
    } else {
      this.setState((prevState) => ({
        newConceptImages: [...validFiles],
        errorMessage: '',
      }));
    }
  };

  async runSimulation() {
    const { selectedLayers, selectedConcepts, targetClass, numberOfImages, numberOfRandomFolders } = this.state;
    const { source } = this.props;
    const data = new FormData();
    data.append('concepts', selectedConcepts);
    data.append('layers', selectedLayers);
    data.append('target_class', targetClass);
    data.append('numberOfImages', numberOfImages);
    data.append('numberOfRandomFolders', numberOfRandomFolders);
    data.append('source', source);
    try {
      this.setState({ progress: true })
      await this.props.explain(data);
    } finally {
      this.setState({ progress: false })
    }

  }

  addNewConcept = async () => {
    const { newConceptName, newConceptImages } = this.state;
    if (!newConceptName) {
      this.setState({ errorMessage: 'Concept name is required.' });
      return;
    }
    const data = new FormData();
    data.append('name', newConceptName);
    for (const f of newConceptImages) {
      data.append('files', f);
    }

    await this.props.createConcept(data);
    const { conceptList } = this.state;
    if (conceptList.indexOf(newConceptName) === -1) {
      conceptList.push(newConceptName);
    }
    this.setState(() => ({
      newConceptName: '',
      newConceptImages: [],
      errorMessage: '',
      isModalOpen: false,
      conceptList: conceptList
    }));
    //
    this.props.loadConcept(newConceptName)
  };

  handleModalOpen = () => {
    this.setState({ isModalOpen: true, errorMessage: '' });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      newConceptName: '',
      newConceptImages: [],
      errorMessage: '',
    });
  };

  render() {
    const { chart, images, conceptsImages, targets } = this.props;
    const { numberOfImages, numberOfRandomFolders, targetClass, selectedConcepts, selectedLayers, isModalOpen, newConceptName, newConceptImages, errorMessage, conceptList } = this.state;
    return (
      <ConfiguratedContainer className="project-explaination-tcav" configuration={{
        maxWidth: '968px',
        margin: 'auto',
        marginBottom: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '10px'

      }}>
        <ConfiguratedContainer configuration={{
          fontSize: '40px',
          marginBottom: '30px'
        }}>

          <Title>
            Resulats explanation with TCAV
          </Title>
        </ConfiguratedContainer>
        <p>
          Run the explanation with TCAV to detect the prevelant concepts in the target images.
        </p>
        <Box sx={{
          display: 'flex',
          gap: '10px',
          marginBottom: '10px'
        }}>
          <Box sx={{
            flex: 1
          }}>
            <FormControl fullWidth className="tcav-target-class">
              <InputLabel>Target Class</InputLabel>
              <WhiteSelect
                value={targetClass}
                label="Target Class"
                name="targetClass"
                onChange={this.onSelectTarget}
              >
                {targets.map(target => (<MenuItem value={target}>{target}</MenuItem>))}
              </WhiteSelect>
            </FormControl>
          </Box>
          <Box sx={{
            flex: 1
          }}>
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Layers</InputLabel>
              <WhiteSelect
                multiple
                value={selectedLayers}
                onChange={this.handleLayersToggle}
                input={<OutlinedInput label="Layers" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {['inception4c', 'inception4d', 'inception4e'].map((layer) => (
                  <MenuItem key={layer} value={layer}>
                    <Checkbox checked={selectedLayers.indexOf(layer) > -1} />
                    <ListItemText primary={layer} />
                  </MenuItem>
                ))}
              </WhiteSelect>
            </FormControl>
          </Box>
        </Box>
        {images.length > 0 && <Box>
          <Title>Target Images</Title>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
            gap: '5px',
            padding: '5px'
          }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={`data:image/jpeg;base64,${image.data}`}
                alt={`Image ${index}`}
                style={{ width: '100px', height: '100px' }}
              />
            ))}
          </Box>
        </Box>}


        <Box sx={{
          marginTop: '20px',
          display: 'flex',
          gap: '10px',
          marginBottom: '10px'
        }}>
          <WhiteTextField
            fullWidth
            name="numberOfImages"
            value={numberOfImages}
            label="Number of images"
            type="number"
            onChange={this.onChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <WhiteTextField
            fullWidth
            name="numberOfRandomFolders"
            value={numberOfRandomFolders}
            label="Number of random folders"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 10 } }}
            onChange={this.onChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>



        {conceptList.map((concept) => (
          <Box sx={{
          }}><FormControlLabel
              key={concept}
              control={

                <Checkbox
                  className={`project-concept-${concept}`}
                  checked={selectedConcepts.includes(concept)}
                  onChange={() => this.handleConceptToggle(concept)}
                />

              }
              label={concept}
            />
            <div>{(conceptsImages[concept] || []).map((image, index) => (
              <img
                key={index}
                src={`data:image/jpeg;base64,${image.data}`}
                alt={`Image ${index}`}
                style={{ width: '70px', height: '70px' }}
              />
            ))}
            </div>
          </Box>
        ))}
        <Button onClick={this.handleModalOpen} variant="contained" startIcon={<AddCircleOutlineIcon />}>
          Add Concept
        </Button>

        <ConfiguratedContainer
          id="explanation-tcav-container"
          configuration={{
            borderRadius: '5px',
            marginTop: '20px'
          }}>
          <Button
            disabled={selectedConcepts.length === 0 || selectedLayers.length === 0}
            onClick={this.runSimulation}
            variant="contained"
          >
            Explain results with TCAV
          </Button>

        </ConfiguratedContainer>

        {this.state.progress && <Box sx={{ width: '100%', marginTop: '30px', marginBottom: '10px' }}>
          <LinearProgress />
        </Box>}

        {chart && <ConfiguratedContainer className='chart-tcav' configuration={{
          height: "500px",
          width: "900px"
        }}>
          <p>TCAV method has detected the following concepts in the provided images.</p>
          <BarChart data={chart} />
        </ConfiguratedContainer>}
        <Modal open={isModalOpen} onClose={this.handleModalClose}>
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2" gutterBottom>
              Add New Concept
            </Typography>
            <TextField
              label="Concept Name"
              value={newConceptName}
              onChange={(e) => this.setState({ newConceptName: e.target.value })}
              fullWidth
              margin="normal"
            />
            <DropzoneComponent onDrop={this.handleFileUpload} />
            <Typography variant="body2">
              {newConceptImages.length} image{newConceptImages.length !== 1 ? 's' : ''} uploaded
            </Typography>
            {errorMessage && (
              <Typography variant="body2" color="error" marginTop="10px">
                {errorMessage}
              </Typography>
            )}
            <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
              <Button variant="contained" color="primary" onClick={this.addNewConcept}>
                Add Concept
              </Button>
              <Button variant="outlined" color="secondary" onClick={this.handleModalClose} sx={{ marginLeft: '10px' }}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </ConfiguratedContainer>
    );
  }
}

function mapStoreToProps(store) {
  return {
    chart: store.charts.chart,
    images: store.images.images,
    conceptsImages: store.concepts,
    source: store.models.source,
    targets: store.executions.targets
  };
}

export default withRouter(connect(mapStoreToProps, { createConcept, getImagesOfTarget, getChart, explain: explainTCAV, loadConcept, loadTarget })(ExplanationTCAV));
