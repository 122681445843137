import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from '../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { Button, Link } from '@mui/material';
import { createProject } from './../../actions/projects';

class CreatePoject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onCreatePoject = this.onCreatePoject.bind(this);
        this.onKeyEnter = this.onKeyEnter.bind(this);
    };

    onKeyEnter(event) {
        if (event.key === 'Enter' && this.state.isValid) {
            this.onCreatePoject();
        }
    }

    isValid() {
        const { name } = this.state;
        if (
            name
           
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    
    onCreatePoject() {
        
        const {
            name
        } = this.state;
        this.setState({
            isSubmitting: true
        }, async () => {
            try {
                await this.props.createProject({
                    name
                });
                const { navigate } = this.props;
                navigate('/home');
                this.setState({
                    isSubmitting: false
                });
            } catch (err) {
                this.setState({
                    isSubmitting: false,
                    error: true,
                    status: err.response ? err.response.data.code : "Error create project, please try again",
                    message: err.response ? err.response.data.message : "Error create project, please try again"
                });
            }
        });


    }

    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }


    render() {
        const {
            name
        } = this.state;
        return (
            <ConfiguratedContainer
                configuration={{
                    width: '100%',
                    height: '100%',
                    display: 'flex'
                }}>
                <ConfiguratedContainer configuration={{
                    display: 'table',
                    height: '100%',
                    // width: '40%',
                    flex: 1,
                    backgroundColor: 'white',
                    ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                        width: '100%',
                    },

                }}>
                    <ConfiguratedContainer configuration={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: '100%',
                        },
                    }}>

                        <ConfiguratedContainer configuration={{
                            backgroundColor: 'white',
                            width: '375px',
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '5px',
                            ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                                height: 'calc(100% + 60px)',
                                // border: 'solid',
                                paddingTop: '60px',
                                // paddingBottom: '20px'
                            },
                        }}>
                            {this.state.error && <ConfiguratedContainer configuration={{
                                // display: 'flex',
                                padding: ' 0 20px',
                                minHeight: '50x',
                                backgroundColor: '#FFCDD2',
                                marginRight: '5px',
                                borderRadius: '10px',
                                // borderRolor: '#81C784',
                                paddingTop: '10px',
                                paddingBottom: '1px',
                                marginBottom: '20px'
                            }}>
                                <ConfiguratedContainer>
                                    {this.state.message}
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    height: '10px'
                                }}>

                                </ConfiguratedContainer>
                            </ConfiguratedContainer>}

                            {/* <Title>CreatePoject</Title> */}
                            <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                                <CommonInput
                                    title='Project Name'
                                    name='name'
                                    value={name}
                                    error={'Name is required'}
                                    onValueChange={this.onValueChange}
                                    onKeyPress={this.onKeyEnter}
                                />
                            </ConfiguratedContainer>
                            

                       
                            
                            <ConfiguratedContainer configuration={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.onCreatePoject}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    onKeyPress={this.onKeyEnter}
                                    variant="contained">
                                    Create project
                                </LoadingButton>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
    };
}

export default withRouter(withTheme(connect(mapStoreToProps, { createProject })(CreatePoject)));