import axios from 'axios';
import * as types from '../constants/ActionTypes';

const defaultState = {
  chart: null,
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_CHART: {
        return { ...state, chart: action.payload };
      }
      case types.RESET_CHART: {
      return { ...state, chart: null };

    }

    default:
      return { ...state }
  }
}