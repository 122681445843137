import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';

export function getChart(projectId) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/charts/${projectId}`)
            .then(response => {
                dispatch({
                    type: types.GET_CHART,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: types.RESET_CHART
                });
            });
    }
}
