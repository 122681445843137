import { combineReducers } from 'redux';
import projects from './projects';
import charts from './charts';
import executions from './executions';
import images from './images';
import shap from './shap';
import cam from './cam';
import models from './models';
import concepts from './concepts';

const rootReducer = combineReducers({
  projects,
  charts,
  executions,
  images,
  shap,
  cam,
  models,
  concepts
});

export default rootReducer;
