import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';


export function getProjects() {
  return function (dispatch) {
    return axios.get(`${API_URL}/api/projects/`)
      .then(response => {
        dispatch({
          type: types.GET_PROJECTS,
          payload: response.data
        })
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export function createProject(project) {
  return function (dispatch) {
      return axios.post(`${API_URL}/api/projects/`, project);
  }
}



