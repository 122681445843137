import * as types from '../constants/ActionTypes';

const defaultState = {
  images: [],
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_SHAP: {
      return { ...state, images: [...action.payload] };
    }
    case types.RESET_SHAP: {
      return { ...state, images: [] };
    }

    default:
      return { ...state }
  }
}