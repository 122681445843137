import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function ResultsBarChart({ data }) {

  const valueFormatter = (value) => `${value * 100}%`;
  const series = [];
  for (const concept of Object.keys(data[0])) {
    if (concept !== 'layer' && !concept.startsWith('random_')) {
      const name = concept.charAt(0).toUpperCase() + concept.slice(1);
      series.push({ dataKey: concept, label: name, valueFormatter })
    }
  }

  return (
    <BarChart
      dataset={data}
      xAxis={[{ scaleType: 'band', dataKey: 'layer' }]}
      series={series}
    />
  );
}