import React, { Component } from 'react';
import Home from './pages/Home';

import { Routes, Route, Navigate } from 'react-router-dom';
import HeaderMenuLayout from 'components/layout/HeaderMenuLayout';
import TermesConditions from 'pages/legal/TermsConditions';
import ExplanationTCAV from 'pages/projects/ExplanationTCAV';
import ExplanationSHAP from 'pages/projects/ExplanationSHAP';
import ExplanationCAM from 'pages/projects/ExplanationCAM';
import CreateProject from 'pages/projects/CreateProject';
import RunExecution from 'pages/projects/RunExecution';

class Router extends Component {

  render() {

    return (
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HeaderMenuLayout Outlet={Home} />} />
        {/* <Route path="/terms-conditions" element={<HeaderMenuLayout Outlet={TermesConditions} />} />
        <Route path="/execute/:id" element={<HeaderMenuLayout Outlet={RunExecution} />} />
        <Route path="/explain/tcav/:id" element={<HeaderMenuLayout Outlet={ExplanationTCAV} />} />
        <Route path="/explain/shap/:id" element={<HeaderMenuLayout Outlet={ExplanationSHAP} />} />
        <Route path="/explain/cam/:id" element={<HeaderMenuLayout Outlet={ExplanationCAM} />} />
        <Route path="/create-project" element={<HeaderMenuLayout Outlet={CreateProject} />} /> */}
        
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Routes>
    );
  }
}



export default Router;

