import * as types from '../constants/ActionTypes';

const defaultState = {
  
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_CONCEPT: {
        const name = action.name;
        const images = [...action.images];
        return { ...state, [name]: [...images]};
    }

    default:
      return { ...state }
  }
}
