import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { ConfiguratedContainer, withRouter } from 'components/common';

class TermesConditions extends Component {
    render() {
        const { theme } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '1000px',
                paddingRight: '20px',
                textAlign: 'justify',
                margin: 'auto',
                ['@media (max-width: 1000px)']: { // eslint-disable-line no-useless-computed-key
                marginLeft: '-20px',

                },
            }}>
                <ConfiguratedContainer configuration={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    color: theme.palette.primary.main,
                    fontWeight: '500',
                    fontSize: '30px',
                    marginLeft: '20px'
                }}>
                    Terms of use
                </ConfiguratedContainer>
                <ol>
                    <li>
                        <b>Termes 1: </b>Termes and conditions1.

                    </li>
                </ol>
            </ConfiguratedContainer>
        );
    }
}

export default withRouter(withTheme(TermesConditions));