import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExplanationTCAV from './projects/ExplanationTCAV';
import ExplanationCAM from './projects/ExplanationCAM';
import ExplanationSHAP from './projects/ExplanationSHAP';
import styled from 'styled-components';
import ImageUploader from './projects/ImageUploader';
import { resetCAM, resetSHAP } from '../actions/executions';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import { runExecution, loadTarget } from '../actions/executions';
import { updateModel, updateSource } from '../actions/models';
import { connect } from 'react-redux';
import ModelPerformance from './performances/ModelPerformance';
import { Button, Select, MenuItem, Box } from '@mui/material';

const Title = styled.div`
  margin-left: 10px;
`;

const Header = styled.header`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  color: #464853;
  font-family: 'Caveat', cursive;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4em;
  }
`;
const NavLinks = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [model, setModel] = React.useState('resnet50');
  const [source, setSource] = React.useState('imageNet');

  const dispatch = useDispatch();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onImagechanges = (data) => {
    const newFiles = data.map(image => {
      if (image instanceof File) {
        return image;
      } else if (image.file instanceof File) {
        return image.file;
      }
    });
    setFiles(newFiles);

    dispatch(resetCAM());
    dispatch(resetSHAP());
  }

  const onModelChange = (data) => {
    setModel(data.target.value);
    dispatch(updateModel(data.target.value))
  }

  const onSourceChange = (event) => {
    setSource(event.target.value)
    dispatch(updateSource(event.target.value))
    dispatch(loadTarget(event.target.value))
  }

  const executeModel = () => {
    const data = new FormData();
    for (const f of files) {
      data.append('files', f);
    }

    dispatch(runExecution(data));
  }

  return (
    <Box sx={{ width: '100%', marginTop: '20px' }}>
      <Header id="home-header">
        <Logo to="/">Friedrich Schiller University Jena</Logo>
        <NavLinks>
          <Box >
            <Select
              id="home-select-source"
              name="selectSource"
              fullWidth
              value={source}
              onChange={onSourceChange}
            >
              <MenuItem key={"imageNet"} value={"imageNet"}>ImageNet</MenuItem>
              <MenuItem key={"CIFAR"} value={"CIFAR"}>CIFAR</MenuItem>
            </Select>
          </Box>
          <Box >
            <Select
              name="selectedStock"
              id="home-select-model"
              fullWidth
              labelId="stock-selector-label"
              value={model}
              onChange={onModelChange}
            >
              <MenuItem key={"resnet50"} value={"resnet50"}>
                Resnet50
              </MenuItem>
              <MenuItem key={"inceptionV3"} value={"inceptionV3"}>
                InceptionV3
              </MenuItem>
            </Select>
          </Box>
        </NavLinks>
      </Header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className="home-model-performance" label="Model Performance" {...a11yProps(0)} />
          <Tab className="home-concept-explainability" label="Concept Explainability" {...a11yProps(1)} />
          <Tab className="home-visual-explainability" label="Visual Explanability" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ModelPerformance />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <ExplanationTCAV  />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box className="home-explanation-shap-cam"  sx={{
          padding: '10px',
          backgroundColor: 'white',
          borderRadius: '10px'
        }}>
          <ImageUploader onImagechanges={onImagechanges} />
          <Box sx={{
            display: 'flex'
          }}>
            {files.map((image, index) => (
              <Box sx={{
                // border: 'solid',
                padding: '0 5px 10px 10px'
              }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Image ${index}`}
                  style={{ width: '200px', height: '200px', margin: '5px' }}
                />
                {props.executionList && props.executionList[index] && <Title>{`${props.executionList[index].label.toUpperCase()}: ${props.executionList[index].value}%`}</Title>}
              </Box>
            ))}
          </Box>

          <Button
            onClick={executeModel}
            variant="contained"
          >
            Execute
          </Button>
        </Box>

        <Box sx={{
          marginTop: '20px',
          // border: 'solid',
          display: 'flex',
          gap: '20px'
        }}>
          <Box sx={{
            flex: 1,
            backgroundColor: 'white',
            borderRadius: '10px'
          }}>

            <ExplanationCAM files={files} />

          </Box>
          <Box sx={{
            flex: 1,
            backgroundColor: 'white',
            borderRadius: '10px'
          }}>
            <ExplanationSHAP files={files} />

          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}

function mapStoreToProps(store) {
  return {
    executionList: store.executions.execution,
  };
}


export default connect(mapStoreToProps, {})(BasicTabs);
