import * as types from '../constants/ActionTypes';

const defaultState = {
  model: 'resnet50',
  source: 'imageNet'
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.CHANGE_MODEL: {
        return { ...state, model: action.payload };
    }

    case types.CHANGE_SOURCE: {
      return { ...state, source: action.payload };
    }
    
    default:
      return { ...state }
  }
}

