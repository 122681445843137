import * as types from '../constants/ActionTypes';

const defaultState = {
  images: [],
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_CAM: {
        return { ...state, images: [...action.payload] };
    }

    default:
      return { ...state }
  }
}