export const API_URL = 
  process.env.NODE_ENV === 'production' ? window.location.origin : 'http://127.0.0.1:5000';
 

export const regex = {
  emailRegex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  textRegex: /^[\s\S]*$/,
  inputRegex: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  passwordRegex: /^.{3,}/u,
  // passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
};

