import axios from 'axios';
import * as types from '../constants/ActionTypes';

const defaultState = {
  projects: [],
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_PROJECTS: {
        return { ...state, projects: [...action.payload.projects] };
    }

    default:
      return { ...state }
  }
}
