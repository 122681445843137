import React from 'react';
import ImageUploading from 'react-images-uploading';
import Button from '@mui/material/Button';
import { ConfiguratedContainer } from 'components/common';
import { Component } from 'react';


class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.onChange = this.onChange.bind(this);
  }


  onChange(data) {
    console.log('-----onchange- iamgesw', data);
    this.props.onImagechanges(data)
  }

  render() {
    const maxNumber = 20;
    const { files } = this.props;

    return (
      <div className="App">
      <ImageUploading
        multiple
        value={files}
        onChange={this.onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          files,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <Button
              // fullWidth
              onClick={onImageUpload}
              variant="outlined"
            >
              Upload Images
            </Button>
            <ConfiguratedContainer configuration={{
              marginTop: '20px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
            }} className="image-item">
            </ConfiguratedContainer>
          </div>
        )}
      </ImageUploading>
    </div>
    );


  }
}






export default ImageUploader;
