import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';

export function runExecution(data) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/executions/execute`, data, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set correct Content-Type
            }
        }).then(response => {
            dispatch({
                type: types.GET_EXECUTION,
                payload: response.data.result
            })
        });
    }
}

export function getExecution(executionId) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/executions/${executionId}`)
            .then(response => {
                dispatch({
                    type: types.GET_EXECUTION,
                    payload: response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function explainTCAV(data) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/executions/tcav/explain`, data, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set correct Content-Type
            }
        }).then(response => {
            console.log(response)
            dispatch({
                type: types.GET_CHART,
                payload: response.data
            });
        });
    }
}

export function explainSHAP(data) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/executions/shap/explain`, data, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set correct Content-Type
            }
        }).then(response => {
            dispatch({
                type: types.GET_SHAP,
                payload: response.data.images
            });
        });
    }
}

export function explainCAM(data) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/executions/cam/explain`, data, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set correct Content-Type
            }
        }).then(response => {
            dispatch({
                type: types.GET_CAM,
                payload: response.data.images
            });
        });;
    }
}

export function deleteExcution(executionId) {
    return function (dispatch) {
        return axios.delete(`${API_URL}/api/executions/${executionId}`);
    }
}

export function resetCAM() {
    return function (dispatch) {
        dispatch({
            type: types.GET_CAM,
            payload: []
        });
    }
}

export function resetSHAP() {
    return function (dispatch) {
        dispatch({
            type: types.RESET_SHAP
        });
    }
}

export function loadTarget(source) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/executions/source/${source}`)
        .then(response => {
            dispatch({
                type: types.GET_TARGETS,
                payload: response.data.targets
            });
        });
    }
}
