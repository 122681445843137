import { styled } from '@mui/material/styles';
// import { Outlet } from 'react-router-dom';
import React, { Component } from 'react';


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;

const RootStyle = styled('div')({
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    overflow: 'auto',
    height: '100%',
    width: '100%',
    // paddingLeft: '15px',
    paddingTop: APP_BAR_MOBILE,
    paddingBottom: 0,
    // marginTop: '10px',
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingBottom: 10,
        marginTop: '0'
    }
}));

class HeaderMenuLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.setOpen = this.setOpen.bind(this);
    }




    setOpen(open) {
        this.setState({ open });
    }


    render() {
        const { open } = this.state;
        const { Outlet, person } = this.props;
        return (
            <RootStyle>
                {/* <Header onOpenSidebar={() => this.setOpen(true)} /> */}
                {/* <DashboardSidebar person={person} isOpenSidebar={open} onCloseSidebar={() => this.setOpen(false)} /> */}
                <MainStyle>
                    <Outlet />
                </MainStyle>
            </RootStyle>
        );
    }
}

export default HeaderMenuLayout;
