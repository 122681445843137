import { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { getChart } from 'actions/charts';
import { explainSHAP } from 'actions/executions';
import { getShapImages } from 'actions/images';
import Title from 'components/common/Title';
import LinearProgress from '@mui/material/LinearProgress';



class ExplanationTCAV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: false,
            files: []
        };
        this.onChange = this.onChange.bind(this);
        this.runSimulation = this.runSimulation.bind(this);
    }

 
    onChange(data) {
        this.setState({
            [data.name]: data.value
        });
    }



    async runSimulation() {
        try{
            const { files } = this.props;
            this.setState({
                progress: true
            })
            const data = new FormData();
            for (const f of files) {
                data.append('files', f);
            }
            await this.props.explain(data);
        } finally {
            this.setState({
                progress: false
            });
        }
    }

    render() {
        const { imagesShap } = this.props;
        const {progress} = this.state;

        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '768px',
                margin: 'auto',
                marginBottom: '10px',
                padding: '0 10px'
            }}>
                <ConfiguratedContainer configuration={{
                    fontSize: '40px',
                    marginBottom: '30px'
                }}>

                    <Title>
                        Resulats explanation with SHAP
                    </Title>
                </ConfiguratedContainer>

                <p>
                    Run the explanation with SHAP.
                </p>
                

                <ConfiguratedContainer configuration={{
                    borderRadius: '5px',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: 'calc(100% + 60px)',
                        paddingTop: '60px',
                    },
                }}>
                    <Button
                        onClick={this.runSimulation}
                        variant="contained"
                    >
                        Explain results with SHAP
                    </Button>

                </ConfiguratedContainer>

                <p>
                    Results of SHAP explanation are the following:
                </p>

                {progress && <LinearProgress />}
                {imagesShap.map((image, index) => (
                    <img
                        key={index}
                        src={`data:image/jpeg;base64,${image.data}`}
                        alt={`Image ${index}`}
                        style={{ width: '200px', height: '200px', margin: '5px' }}
                    />
                ))}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        imagesShap: store.shap.images
    };
}

export default withRouter(connect(mapStoreToProps, { getChart, explain: explainSHAP, getShapImages })(ExplanationTCAV));
