import { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { getChart } from 'actions/charts';
import { runExecution, getExecution } from 'actions/executions';
import { getImages } from 'actions/images';
import ImageUploader from './ImageUploader';
import Title from 'components/common/Title';


class RunExecution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
        this.onImagechanges = this.onImagechanges.bind(this);
        this.runExecution = this.runExecution.bind(this);
        this.goToExplanationTCAV = this.goToExplanationTCAV.bind(this);
        this.goToExplanationSHAP = this.goToExplanationSHAP.bind(this);
        this.goToExplanationCAM = this.goToExplanationCAM.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { params } = this.props;
        this.props.getExecution(params.id);
        this.props.getImages(params.id);
    }

    goToExplanationTCAV() {
        const { navigate, execution } = this.props;
        navigate('/explain/tcav/' + execution.execution_id);
    }

    goToExplanationSHAP() {
        const { navigate, execution } = this.props;
        navigate('/explain/shap/' + execution.execution_id);
    }

    goToExplanationCAM() {
        const { navigate, execution } = this.props;
        navigate('/explain/cam/' + execution.execution_id);
    }

    onImagechanges(data) {
        this.setState({
            files: data.map(image => {
                if (image instanceof File) {
                    return image;
                } else if (image.file instanceof File) {
                    return image.file;
                }
            })
        })
    }

    async runExecution() {
        const { params } = this.props;
        const execution_id = params.id;
        const { files } = this.state;
        const data = new FormData();
        for (const f of files) {
            data.append('files', f);
        }
        data.append('execution_id', execution_id);
        await this.props.runExecution(data);
        await this.props.getExecution(execution_id);
        await this.props.getImages(execution_id);
        this.setState({
            files: []
        });
    }

    render() {
        const { files } = this.state;
        const { execution, images } = this.props;
        const results = execution !== null && execution.value ? JSON.parse(execution.value) : [];

        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '768px',
                margin: 'auto',
                marginBottom: '10px'
            }}>
                <ConfiguratedContainer configuration={{
                    fontSize: '40px',
                    marginBottom: '30px'
                }}>

                    <Title>
                        Run Execution
                    </Title>
                </ConfiguratedContainer>

                <ImageUploader files={files} onImagechanges={this.onImagechanges} />

                {files.map((image, index) => (
                    <img
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index}`}
                        style={{ width: '200px', height: '200px', margin: '5px' }}
                    />

                ))}

                {this.state.files.length == 0 && images.map((image, index) => (
                    <img
                        key={index}
                        src={`data:image/jpeg;base64,${image.data}`}
                        alt={`Image ${index}`}
                        style={{ width: '200px', height: '200px', margin: '5px' }}
                    />
                ))}



                <ConfiguratedContainer configuration={{
                    // width: '375px',
                    margin: 'auto',
                    marginBottom: '10px',
                    // padding: '20px',
                    borderRadius: '5px',
                    // textAlign: 'center',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: 'calc(100% + 60px)',
                        // border: 'solid',
                        paddingTop: '60px',
                        // paddingBottom: '20px'
                    },
                }}>
                    <Button
                        // fullWidth
                        onClick={this.runExecution}
                        variant="contained"
                    >
                        Calculate results
                    </Button>

                </ConfiguratedContainer>

                {results.length > 0 && <ConfiguratedContainer configuration={{}}>
                    <h1>Results : </h1>
                    {results.map(v => <Title> This is a {v.label} with <b>{v.value}% accuracy</b></Title>)}
                </ConfiguratedContainer>}

                {this.state.files.length == 0 && <ConfiguratedContainer configuration={{
                    width: 'calc(100% - 20px)',
                    display: 'flex',
                    margin: '20px 0px',
                    flexWrap: 'wrap',
                    gap: 'calc(4% / 2)'
                }}>
                    <Button

                        onClick={this.goToExplanationTCAV}
                        variant="contained"
                    >
                        Explain results with TCAV
                    </Button>
                    <Button

                        onClick={this.goToExplanationSHAP}
                        variant="contained"
                    >
                        Explain results with SHAP
                    </Button>
                    <Button
                        onClick={this.goToExplanationCAM}
                        variant="contained"
                        >
                        Explain results with CAM
                        </Button>
                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        chart: store.charts.chart,
        execution: store.executions.execution,
        images: store.images.images
    };
}

export default withRouter(connect(mapStoreToProps, { getImages, getChart, runExecution, getExecution })(RunExecution));
