export const GET_PERSON = 'GET_PERSON';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_PROJECTS = 'GET_PROJECTS';

export const GET_IMAGES = 'GET_IMAGES';
export const GET_CHART = 'GET_CHART';
export const RESET_CHART = 'RESET_CHART';
export const GET_SHAP = 'GET_SHAP';
export const RESET_SHAP = 'RESET_SHAP';

export const GET_CAM = 'GET_CAM';


export const GET_EXECUTION = 'GET_EXECUTION';
export const GET_TARGETS = 'GET_TARGETS';

export const CHANGE_MODEL = 'CHANGE_MODEL';
export const CHANGE_SOURCE = 'CHANGE_SOURCE';

export const GET_CONCEPT = 'GET_CONCEPT';
